import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_weixin_app:navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,     //判断当前环境是否微信环境
    is_weixin_applet:navigator.userAgent.toLowerCase().indexOf('miniprogram') != -1,     //判断当前环境是否微信小程序环境
    is_weixin_work:navigator.userAgent.toLowerCase().indexOf('wxwork') != -1,     //判断当前环境是否企业微信
    is_iphone:navigator.userAgent.toLowerCase().indexOf('iphone') != -1,     //判断当前环境是否苹果手机环境
    is_ipad:navigator.userAgent.toLowerCase().indexOf('ipad') != -1,     //判断当前环境是否苹果ipad环境
    is_android:navigator.userAgent.toLowerCase().indexOf('android') != -1,     //判断当前环境是否安卓手机环境
    is_QQBrowser:navigator.userAgent.toLowerCase().indexOf('mqqbrowser') != -1,     //判断当前环境是否QQ浏览器环境
    is_QQ_app:String(navigator.userAgent.toLocaleLowerCase().match(/QQ/i)) == "qq",     //判断当前环境是否QQ环境
    
    paymentState: false, //支付状态
    companyName: '', //公司名字
  },
  mutations: {
    //更改支付状态
    CHANGE_PAYMENT(state, option) {
      state.paymentState = option.state
    },
    CHANGE_COMPANY_NAME(state, option) {
      state.companyName = option.name
    }

  },
  actions: {
  },
  modules: {
  }
})
