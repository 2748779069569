import { Dialog, Toast } from 'vant';
import store from '@/store'
import router from '@/router'
import {
    jssdksignApi, //获取微信分享的appid等信息
} from "@/api/index";
import wxapi from "@/libs/wxapi";

let util = {};


//统一调用分享描述
util.shareData = () => {
    return {
        title: '优信中国', // 分享标题
        desc: '优信中国', // 分享描述
        link: '',
        imgUrl: location.origin + '/logo.jpg', // 分享图标
    };
}

//h5的复制功能
util.copyText = (textNode)=> {
    let textNodeStr = textNode.innerHTML.replace(/<[^>]+>/g,"");
    // console.log(textNodeStr)
    // if(!navigator.clipboard){
        let textarea = document.createElement("textarea");//要复制文字的节点
        textarea.innerHTML = textNodeStr; 
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.select(); // 选择对象
        let isCopy = document.execCommand('Copy');
        util.yksToast(isCopy?"复制成功":"复制失败");   
        document.body.removeChild(textarea);
    //     return;
    // }
    // console.log(textNodeStr)
    // navigator.clipboard.writeText(textNodeStr).then(function() {
    //     util.yksToast("复制成功"); 
    // }, function(err) {
    //     util.yksToast("复制失败"); 
    // });
};


//微信分享触发事件-传值是字符串
util.weixinShareFun = (option) => {
    if(store.state.is_weixin_app){     //在页面的window上挂载分享share方法，方便外部调用
        let urlParams = window.location.href.split('#')[0];
        let jsApiList = ["updateTimelineShareData",
            "updateAppMessageShareData"];
        //获取微信分享的appid等信息
        jssdksignApi({
            url: urlParams,
            jsApiList: jsApiList.join(','),
        }).then((data) => {
            wxapi.wxRegister(data.data, option); //data是微信配置信息，option是分享要配置的内容
        });
    }
}

//判断当前字符串是否是空字符串-传值是字符串
util.is_notNullStr = (str) => {
    if(str && str.trim()!='')return true
    return false
}

//输入的手机验证-
util.phoneValidation = (phone) => {
    if(!phone)return "请输入手机号";
    if(phone.length != 11 || !/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))return "请输入正确手机号";
    return "";
}

//全局通用的页面跳转
util.skipUrl = (url,queryObj,routerStr="push") => {
    if(!url)return;
    let query = {};
    if(queryObj)Object.keys(queryObj).forEach((item)=>{
        query[item] = queryObj[item];
    })
    if(url.indexOf('http') === 0){
        location.href = url;
    }else if(url.indexOf('/') === 0){
        router[routerStr]({path: url,query,});
    }
};

export default util;