import axios from "axios";
import { Toast } from 'vant';
// import store from "@/store";

console.log(process.env.NODE_ENV + '环境');
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 发送请求之前需要做些什么
        // if (store.getters.token) {
        //     config.headers["X-Token"] = getToken();
        //     config.headers["Authorization"] = store.getters.token;
        // }
        return config;
    },
    error => {
        // 处理请求错误
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(

    response => {
        const res = response.data;
        // console.log(res);
        // let token = response.headers["authorization"];
        // if (token) {
        // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
        // store.dispatch("user/token", token);
        // }
        return res;
    },
    error => {
        Toast('请求错误');
        return Promise.reject(error);
    }
);

export default service;
