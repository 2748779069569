<template>
    <div class="about-main">
        <div class="about">
            <div class="font18">关于我们</div>
            <div class="context">
                优信立信计划由北京优信立信企业信用评估中心运营，旨在通过立信计划弘扬诚实守信，传播诚信美德，携手共同创健康诚信的经营环境
            </div>
            <div class="gong-wang">
                <div @click="gongshangFun" class="gong-wang-info">
                    <img
                        class="gongimg"
                        src="@/assets/img/icon-gs.png"
                        alt=""
                    />
                    <span class="fontW">工商登记信息></span>
                </div>
                <div @click="beianchaxunFun" class="gong-wang-info">
                    <img
                        class="wangimg"
                        src="@/assets/img/icon-wa.png"
                        alt=""
                    />
                    <span class="fontW">网安备案信息></span>
                </div>
            </div>
            <!-- <div class="contact-way">
                <div class="way">联系电话: {{ phone }}</div>
                <div class="way">客服微信: {{ weixin }}</div>
            </div> -->
        </div>
        <div class="records">{{beian}}</div>
    </div>
</template>

<script>
export default {
    name: "aboutUs",
    data() {
        return {
            phone: 'xxxxxxxxxxx',
            weixin:'xxxxxxx',
            beian:'京ICP备2023006559号-1',
        }
    },
    methods:{
        beianchaxunFun(){
            window.location.href = 'https://beian.miit.gov.cn/'
        },
        gongshangFun(){
            window.location.href = 'https://www.gsxt.gov.cn/'
        }
    }
};
</script>

<style lang="less" scoped>
.about-main {
    width: 350px;
    height: 254px;
    position: relative;
    .records {
        width: 350px;
        margin-top: 15px;
        text-align: center;
        font-size: 11px;
        color: #666666;
    }
}
.about {
    position: relative;
    width: 350px;
    height: 226px;
    background: url("~@/assets/img/info-two.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 15px;
    .font18 {
        position: absolute;
        top: 31px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        font-weight: 500;
    }
    .context {
        position: absolute;
        top: 68px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        width: 100%;
        height: auto;
        padding: 0 18px;
        box-sizing: border-box;
        color: #333333;
    }
    .gong-wang {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 152px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        width: 100%;
        height: 20px;
        padding: 0 49px;
        box-sizing: border-box;
        .gong-wang-info {
            display: flex;
            align-items: center;
            .wangimg,
            .gongimg {
                width: 30px;
                height: 30px;
            }
            .fontW {
                font-size: 12px;
                color: #333333;
                font-weight: 400;
                margin-left: 6px;
            }
        }
    }
    .contact-way {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        width: 100%;
        height: 40px;
        padding: 0 23px;
        box-sizing: border-box;
        .way {
            width: fit-content;
            font-size: 11px;
            color: #ffffff;
        }
    }
}
</style>
