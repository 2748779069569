import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { Popup, Cascader, Toast, Notify, Icon, Skeleton, Loading } from 'vant';
import './util/rem';
import 'vant/lib/index.css';
Vue.use(Popup);
Vue.use(Cascader);
Vue.use(Notify);
Vue.use(Icon);
Vue.use(Skeleton);
Vue.use(Loading);

import moment from 'moment';
Vue.prototype.$moment = moment;

import util from '@/libs/util';
//公共组件util方法挂载在vue原型上
Vue.prototype.util = util

// //判断是否微信环境
// import { init } from "@/util/weixin.js";
// Vue.prototype.$init = init;
// isWeixin();
// function isWeixin() {
//   const ua = window.navigator.userAgent.toLowerCase()
//   if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
//     Vue.prototype.$isWeixin = true;
//   } else {
//     Vue.prototype.$isWeixin = false;
//   }
// }

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
