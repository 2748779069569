<template>
    <!-- @touchmove.prevent,可以在遮罩层中添加 @touchmove.prevent 就可以实现禁止页面滚动,注意 弹窗里面有滚动条的也是无法滚动的 -->
    <div
        v-if="showValue"        
        @click="showValue = false"
        class="shipping-main"
    >
        <div class="context" @click.stop="() => {}">
            <img
                class="close"
                @click="showValue = false"
                src="@/assets/img/close.png"
                alt=""
            />
            <div class="title">填写您的收货信息</div>
            <div class="inputInfo">
                <label for="name">收件人</label>
                <input
                    type="text"
                    key="name"
                    v-model="option.name"
                    placeholder="请填写收件人姓名"
                />
            </div>
            <div class="inputInfo">
                <label for="phone">联系电话</label>
                <input
                    type="text"
                    key="phone"
                    v-model="option.phone"
                    placeholder="请填写联系电话"
                />
            </div>
            <div class="inputInfo">
                <label for="provinces">省市区</label>
                <div
                    class="select-address"
                    :style="
                        !option.provinces ? 'color: #aaa' : ''
                    "
                    @click="show = true"
                >
                    {{ option.provinces ? option.provinces : "所在地区" }}
                </div>
            </div>
            <div class="inputInfo">
                <label for="site">详细地址</label>
                <input
                    type="text"
                    key="site"
                    v-model="option.site"
                    placeholder="请填写详细地址"
                />
            </div>
            <div class="attention">
                *联系方式用于快递，售后等服务，请仔细核对
            </div>
            <div @click="submitFun()" class="btn">
                <div>提交</div>
            </div>
        </div>
        <van-popup 
            v-model="show"
            van-number-keyboard
            safe-area-inset-bottom
            round
            position="bottom"
            @click.stop="()=>{}"
        >            
            <van-cascader
                :field-names="fieldNames"
                v-model="cascaderValue"
                title="请选择所在地区"
                :options="options"
                @close="show = false"
                @finish="onFinish"
            />
        </van-popup>
    </div>
</template>

<script>
import { areaListApi } from "@/api/index.js";
export default {
    name: "shipping",
    data() {
        return {
            showValue: false,
            show: false,
            option: {
                name: "",
                phone: "",
                provinces: "",
                site: "",
                provincesCode: [],
                provincesName: [],
            },
            cascaderValue: "", //初始值
            options: null, //省市区数据
            fieldNames: {
                text: "dict_label",
                value: "dict_value",
                children: "child",
            },
        };
    },
    created() {
        this.getAreaList();
        this.getLocaStrogFun();
    },
    methods: {
        getLocaStrogFun() {
            let address_yuixin = window.localStorage.getItem("address_yuixin");
            if (address_yuixin) {
                this.option.name = JSON.parse(address_yuixin).name;
                this.option.phone = JSON.parse(address_yuixin).phone;
                this.option.provinces = JSON.parse(address_yuixin).provinces;
                this.option.site = JSON.parse(address_yuixin).site;
                this.option.provincesCode[0] =
                    JSON.parse(address_yuixin).provincesCode[0];
                this.option.provincesCode[1] =
                    JSON.parse(address_yuixin).provincesCode[1];
                this.option.provincesCode[2] =
                    JSON.parse(address_yuixin).provincesCode[2];

                this.option.provincesName[0] =
                    JSON.parse(address_yuixin).provincesName[0];
                this.option.provincesName[1] =
                    JSON.parse(address_yuixin).provincesName[1];
                this.option.provincesName[2] =
                    JSON.parse(address_yuixin).provincesName[2];
            }
        },
        getAreaList() {
            areaListApi().then((res) => {
                this.options = res.data.data;
            });
        },
        showFun() {
            this.showValue = true;
        },
        hideFun() {
            this.showValue = false;
        },
        // 全部选项选择完毕后，会触发 finish 事件
        onFinish({ selectedOptions }) {
            // console.log(selectedOptions);
            this.show = false;
            this.option.provinces = selectedOptions
                .map((option) => option.dict_label)
                .join("/");
            this.option.provincesCode = selectedOptions.map(
                (val) => val.dict_code
            );
            this.option.provincesName = selectedOptions.map(
                (val) => val.dict_label
            );
        },
        //提交
        submitFun() {
            var reg =
                /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
            if (!this.option.name) return this.$toast("请填写姓名");
            if (!this.option.phone) return this.$toast("请填写手机号");
            if (!reg.test(this.option.phone))
                return this.$toast("手机号码格式错误");
            if (!this.option.provinces) return this.$toast("请选择省市区");
            if (!this.option.site) return this.$toast("请填写详细地址");
            this.$emit("submit", this.option);
            this.hideFun();
        },
    },
};
</script>

<style lang="less" scoped>
.shipping-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .context {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 350px;
        height: 360px;
        background: url("~@/assets/img/address.png") no-repeat;
        background-size: 100% 100%;
        padding: 27px 23px 0;
        box-sizing: border-box;
        .close {
            position: absolute;
            top: 30px;
            right: 21px;
            width: 20px;
            height: 20px;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 14px;
        }
        .inputInfo {
            width: 100%;
            height: 42px;
            border-bottom: #cccccc 1px solid;
            display: flex;
            align-items: center;
            label {
                width: 88px;
                font-size: 16px;
                font-weight: normal;
            }
            input {
                border: none;
                box-sizing: border-box;
            }
            .select-address {
                width: 200px;
                padding-left: 10px;
            }
        }
        .attention {
            font-size: 11px;
            color: #bd0406;
            margin-top: 11px;
        }
        .btn {
            position: relative;
            width: 296px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background: url("~@/assets/img/submit.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            div {
                position: absolute;
                top: 8px;
            }
        }
    }
}
</style>
