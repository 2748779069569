import http from '../util/request.js'
import { Toast } from 'vant';

function requestHttp(method, url, data, toast = true) {
    if (toast) {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
        });
    }
    return new Promise((resolve, reject) => {
        let requestData = {}
        if (method == 'get') {
            requestData = {
                params: {
                    ...data
                }
            }
        } else {
            requestData = {
                data,
            }
        }
        http({
            method,
            url,
            ...requestData,
        }).then((res) => {
            // if (res.code != 200) return Toast(res.msg);
            if (toast) {
                Toast.clear();
            }
            resolve(res);
        }).catch((err) => {
            if (toast) {
                Toast.clear();
            }
            reject(err)
        })
    })
}

const apiData = {
    areaList: '/api/sys/dict_type/area_list', //省市区地址
    qrCodeInfo: '/api/qrcodeinfo', //二维码扫码信息
    orderInfo: '/api/orderinfo', //订单信息
    index: '/api/index', //领取入口
    saveAddress: '/api/saveaddress', //保存收货地址
    orderForm: '/api/orderform', // 订单表单-产品列表
    orderPay: '/api/orderpay', //订单支付
    openid: '/api/saveWxOpenid', //保存微信openid
    jssdksign: '/api/wx/jssdksign', //微信配置
}
//省市区地址
export function areaListApi(data = {}) {
    return requestHttp('get', apiData.areaList, data, false)
}
//二维码扫码信息
export function qrCodeInfoApi(url, data) {
    return requestHttp('get', apiData.qrCodeInfo + "/" + url, data)
}
//订单信息
export function orderInfoApi(url, data) {
    return requestHttp('get', apiData.orderInfo + "/" + url, data)
}
//领取入口
export function indexApi(url, data) {
    return requestHttp('get', apiData.index + "/" + url, data)
}
//保存收货地址
export function saveAddressApi(data) {
    return requestHttp('post', apiData.saveAddress, data)
}
// 订单表单-产品列表
export function orderFormApi(data) {
    return requestHttp('get', apiData.orderForm, data)
}
//订单支付
export function orderPayApi(data) {
    return requestHttp('post', apiData.orderPay, data)
}
//保存微信openid
export function openidApi(data) {
    return requestHttp('put', apiData.openid, data)
}
//获取微信配置
export function jssdksignApi(data) {
    return requestHttp('get', apiData.jssdksign, data)
}