import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', //首页
    name: 'index',
    component: index
  },
  {
    path: '/:cid', //首页
    name: 'index',
    component: index
  },
  {
    path: '/payment/:cid',
    name: 'payment', //支付费用
    component: () => import('../views/payment/payment.vue')
  },
  {
    path: '/succeed/:cid',
    name: 'succeed', //支付成功
    component: () => import('../views/succeed/succeed.vue')
  },
  {
    path: '/lookinfo/:cid',
    name: 'lookinfo', //扫描牌匾二维码查看信息
    component: () => import('../views/lookinfo/lookinfo.vue')
  },
  {
    path: '/share/:cid',
    name: 'share', //分享页面
    component: () => import('../views/share/share.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // console.log(to)
  //cnzz站长统计
  if(to.path && window._czc)window._czc.push(['_trackPageview',to.path,from.path]);
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});
export default router
