<template>
    <div
        @touchmove.prevent
        v-if="show"
        @click="show = false"
        class="commitment-main"
    >
        <div class="content" @click.stop="() => {}">
            <div class="title">{{ book.title }}</div>
            <div class="text">{{ book.content }}</div>
            <div class="compant">
                <span>{{ book.name }}</span>
                <span>{{ time }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "commitment",
    data() {
        return {
            show: false,
            time: "",
        };
    },
    props:['book'],
    created() {
        this.time = this.$moment().format("YYYY年MM月DD日");
    },
    methods: {
        showFun() {
            this.show = true;
        },
        hideFun() {
            this.show = false;
        },
    },
};
</script>

<style lang="less" scoped>
.commitment-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 320px;
        height: 172px;
        background: url("~@/assets/img/chengnuo-book.png") no-repeat;
        background-size: 100% 100%;
        .title {
            font-size: 16px;
            font-weight: 500;
            color: #be0405;
            text-align: center;
            margin-top: 16px;
        }
        .text {
            padding: 0 20px;
            margin-top: 10px;
            color: #333333;
        }
        .compant {
            padding: 0 23px;
            font-size: 11px;
            display: flex;
            flex-direction: column;
            align-items: end;
            margin-top: 16px;
            color: #666666;
            span{
                &:nth-child(2){
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>